import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button } from "@mui/material";

import ZoomInIcon from "@mui/icons-material/ZoomIn";

import ZoomOutIcon from "@mui/icons-material/ZoomOut";

function DetailPage({ route }) {
  let { state } = useLocation();

  const [contentSize, setContentSize] = useState(18);

  console.log("val", state);

  return (
    <div
      style={{
        padding: 10,
        paddingBottom: 100,
      }}
    >
      <div>
        <div>
          <span
            style={{ color: "white", fontFamily: "Ramabhadra", fontSize: 24 }}
          >
            {state.title}
          </span>
        </div>
        <div>
          <span
            style={{
              color: "white",
              fontFamily: "Mallanna",
              fontSize: contentSize,
            }}
          >
            {state.content}
          </span>
        </div>
      </div>

      <div style={{ position: "fixed", bottom: "20px", right: 10 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            style={{ margin: 5 }}
            onClick={() => setContentSize(contentSize - 1)}
          >
            <ZoomOutIcon />
          </Button>
          <Button
            variant="contained"
            style={{ margin: 5 }}
            onClick={() => setContentSize(contentSize + 1)}
          >
            <ZoomInIcon />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DetailPage;
