import React from "react";

import "./App.css";
import { usePWAInstall } from "react-use-pwa-install";
import HomePage from "./pages/homepage/HomePage";
import { Container } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DetailPage from "./pages/homepage/component/DetailPage";

function App() {
  const install = usePWAInstall();
  return (
    <Router>
      <div style={{ background: "#243447", width: "100%", minHeight: "100vh" }}>
        <Container>
          {install && (
            <div
              style={{
                width: "100%",
                background: "green",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <span style={{ fontWeight: "bold", color: "white" }}>
                Please Install Web App for Fast Experience
              </span>
              <div
                style={{
                  background: "orange",
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 10,
                  marginTop: 10,
                }}
                onClick={install}
              >
                <span style={{ fontSize: 25 }}>Install Apps</span>
              </div>
            </div>
          )}

          <Routes>
            <Route element={<HomePage />} path="/" />
            <Route element={<DetailPage />} path="/detailpage" />
          </Routes>
        </Container>
      </div>
    </Router>
  );
}

export default App;
